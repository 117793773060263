import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import five from "../../Assests/5.jpg";

const ActivitiesMeghauli = () => {
    const object = {
        autoplay: true,
        autoplayTimeout: 3000,
        dots:false
      };
  return (
    <>
      <div id="activities" className="container margin-tb home-activities-title">
        <h1 >Our Activities</h1>
        <OwlCarousel className="owl-theme" loop margin={10} {...object}>
          <div className="item home-activities-item">
            <div className="home-activities-image">
              <img src={five} alt="" className="img-fluid" />
            </div>
            <div className="container-fluid padding-0">
              <div className="row">
                <div className="col-md-9">
                  <p>Elephant Safari</p>
                </div>
                <div className="col-md-3">
                  <p>-&gt;</p>
                </div>
              </div>
            </div>
          </div>
          <div className="item home-activities-item">
            <div className="home-activities-image">
              <img src={five} alt="" className="img-fluid" />
            </div>
            <div className="container-fluid padding-0">
              <div className="row">
                <div className="col-md-9">
                  <p>Elephant Safari</p>
                </div>
                <div className="col-md-3">
                  <p>-&gt;</p>
                </div>
              </div>
            </div>
          </div>
          <div className="item home-activities-item">
            <div className="home-activities-image">
              <img src={five} alt="" className="img-fluid" />
            </div>
            <div className="container-fluid padding-0">
              <div className="row">
                <div className="col-md-9">
                  <p>Elephant Safari</p>
                </div>
                <div className="col-md-3">
                  <p>-&gt;</p>
                </div>
              </div>
            </div>
          </div>
          <div className="item home-activities-item">
            <div className="home-activities-image">
              <img src={five} alt="" className="img-fluid" />
            </div>
            <div className="container-fluid padding-0">
              <div className="row">
                <div className="col-md-9">
                  <p>Elephant Safari</p>
                </div>
                <div className="col-md-3">
                  <p>-&gt;</p>
                </div>
              </div>
            </div>
          </div>
          <div className="item home-activities-item">
            <div className="home-activities-image">
              <img src={five} alt="" className="img-fluid" />
            </div>
            <div className="container-fluid padding-0">
              <div className="row">
                <div className="col-md-9">
                  <p>Elephant Safari</p>
                </div>
                <div className="col-md-3">
                  <p>-&gt;</p>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </>
  );
};

export default ActivitiesMeghauli;
