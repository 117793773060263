import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import six from "../../Assests/6.jpg";

const OurVisitors = () => {
  const object = {
    autoplay: true,
    autoplayTimeout: 3000,
    dots:false
  };
  return (
    <>
      <div className="container margin-tb home-reviews-title">
        <h1 id="our-visitors">Our visitors</h1>
        <OwlCarousel
          className="owl-theme"
          loop
          margin={10}
          {...object}
        >
          <div className="item">
            <div className="home-reviews-image">
              <img src={six} alt="" className="img-fluid" />
            </div>
            <div className="home-reviews-text">
              <h4>John Doe</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="home-reviews-image">
              <img src={six} alt="" className="img-fluid" />
            </div>
            <div className="home-reviews-text">
              <h4>John Doe</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="home-reviews-image">
              <img src={six} alt="" className="img-fluid" />
            </div>
            <div className="home-reviews-text">
              <h4>John Doe</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="home-reviews-image">
              <img src={six} alt="" className="img-fluid" />
            </div>
            <div className="home-reviews-text">
              <h4>John Doe</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </>
  );
};

export default OurVisitors;
