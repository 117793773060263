import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const CarouselMeghauli = () => {
    const object = {
        autoplay: false,
        autoplayTimeout: 3000,
        dots:false
      };
  return (
    <>
      <OwlCarousel id="packages" className="owl-theme" loop {...object}>
        <div className="item home-package-card">
          <p>1 Night / 2 Days</p>
          <h1>Package</h1>
        </div>
        <div className="item home-package-card">
          <p>1 Night / 2 Days</p>
          <h1>Package</h1>
        </div>
        <div className="item home-package-card">
          <p>1 Night / 2 Days</p>
          <h1>Package</h1>
        </div>
        <div className="item home-package-card">
          <p>1 Night / 2 Days</p>
          <h1>Package</h1>
        </div>
      </OwlCarousel>
    </>
  );
};

export default CarouselMeghauli;
