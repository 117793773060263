import React from "react";
import logo from "../Assests/meghauli.png";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg meghauli-nav">
        <div className="container">
          <a className="navbar-brand meghauli-brand" href="#home">
            <img src={logo} alt="" className="img-fluid" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse meghauli-menu"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item active">
                <a className="nav-link" href="#home">
                  Home <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about-us">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#rooms">
                  Rooms
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#packages">
                  Packages
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#activities">
                  Activities
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#our-visitors">
                  Our Visitors
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
