import React from "react";
import logo from "../../Assests/meghauli.png";

const IntroMeghauli = () => {
  return (
    <>
      <div className="container margin-tb">
        <div className="row">
          <div className="col-md-4">
            <div className="home-intro-logo">
              <img src={logo} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-8">
            <div id="about-us" className="home-intro-text">
              <h1>Learn about meghauli resort</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure olor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
            <a href="{{url('#')}}" className="meghauli-btn-1">
              <p>Learn More</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroMeghauli;
