import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container-fluid footer-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-4 footer-text">
              <h5>contact</h5>
              <ul>
                <li>
                  <a href="#">
                    <p>Balkumari, Kathmandu</p>
                  </a>
                </li>
                <li>
                  <a href="mailto:">
                    <p>sample@mail.com</p>
                  </a>
                </li>
                <li>
                  <a href="tel">
                    <p>00-0000-000</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 footer-text">
              <div>
                <h6>logo goes here</h6>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="col-md-4 footer-text">
              <h5>newsletter</h5>
              <h6>subscribe form goes here</h6>
              <ul>
                <li>
                  <a href="{{url('#')}}" target="_blank">
                    <p>facebook</p>
                  </a>
                </li>
                <li>
                  <a href="{{url('#')}}" target="_blank">
                    <p>twitter</p>
                  </a>
                </li>
                <li>
                  <a href="{{url('#')}}" target="_blank">
                    <p>google</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container footer-copyright">
          <p>copyright ©2018, All rights reserved. Meghauli Wildlife Resort</p>
          <p>
            Designed and Developed by:{" "}
            <a href target="_blank">
              Creatu Developers
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
