import React from 'react'
import ActivitiesMeghauli from './HomeComponent/ActivitiesMeghauli'
import CarouselMeghauli from './HomeComponent/CarouselMeghauli'
import IntroMeghauli from './HomeComponent/IntroMeghauli'
import OurVisitors from './HomeComponent/OurVisitors'
import ServiceMeghauli from './HomeComponent/ServiceMeghauli'

const Home = () => {
  return (
    <>
       <div id="home">
       <IntroMeghauli />
        <CarouselMeghauli />
        <ServiceMeghauli />
        <ActivitiesMeghauli />
        <OurVisitors />
       </div>
    </>
  )
}

export default Home